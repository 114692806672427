import Const from '../constants'
import axiosApiInstance from '../interceptors'

class TermOfServiceService {
  getTermOfService() {
    return axiosApiInstance.get(Const.api_url + 'dashboard/term-of-use')
  }
}

export default new TermOfServiceService()
